import { getOnceCode } from '../api/userApi'
import { gotoUimLogin } from '@/utils/uimLogin'
import { refreshToken } from '@/api/commonApi'
import { getUserContact, getShareAccessToken, getAutoLoginUimAccessToken } from '@/api/userApi'
import sha1 from 'sha1'
import store from '@/store/index'
import { FileInfo } from './file'
import { message } from 'ant-design-vue'
import { h } from 'vue'
import ErrorMessage from '@/views/user_center/profile/componets/ErrorMessage.vue'
import { BigNumber } from 'bignumber.js'
// Get yyyy|MM|dd|HH|mm|ss
export function formatDateTime(time, format) {
  let t

  if (typeof time === 'string') {
    t = new Date(Date.parse(time.replace(/-/g, '/')))
  } else t = new Date(time)

  const tf = function (i) {
    return (i < 10 ? '0' : '') + i
  }

  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
    switch (a) {
      case 'yyyy':
        return tf(t.getFullYear())

      case 'MM':
        return tf(t.getMonth() + 1)

      case 'mm':
        return tf(t.getMinutes())

      case 'dd':
        return tf(t.getDate())

      case 'HH':
        return tf(t.getHours())

      case 'ss':
        return tf(t.getSeconds())
    }
  })
}

// /**
//  * 验证登陆留资
//  * @param {*} url 要跳转到URL地址
//  */
// export function verifyLoginContactUs (url, router, lang) {
//   let userInfoObj = JSON.parse(window.sessionStorage.getItem('userInfo'))
//   // 未登陆，跳到登陆页
//   if (!userInfoObj) {
//     // window.location.href = process.env.VUE_APP_UIM_BASE_URL + '/#/login?appId=220927&checkStr=07bb0a191951c5b98f44526099ff2e6d&redirectPath=contact-us?returnUrl=' + url

//     window.location.href = process.env.VUE_APP_UIM_BASE_URL + process.env.VUE_APP_UIM_PATH + lang + '&redirectPath=' + encodeURIComponent('contact-us?' + url)
//   } else {
//     let params = new URLSearchParams(url)
//     let urlParams = decodeURIComponent(params.get('returnUrl'))

//     // 判断是否留资
//     if (!userInfoObj.completeFlag) {
//       // 未留资，跳到留资页面
//       router.push({
//         name: 'contact-us', query: { returnUrl: urlParams }
//       })
//     } else {
//       let arrParams = decodeURIComponent(urlParams)
//       // let params = new URLSearchParams(arrParams)
//       // // 打开文档
//       // urlJumpCompatibleMobile(params.get('url'), '_blank')
//       // // 跳回solution-details
//       // router.push({ name: params.get('pathName'), query: { id: params.get('id') } })

//       SetJumpURL(arrParams, router)
//     }
//   }
// }

/**
 * 验证登陆留资
 * @param {*} targetUrl 目标URL地址
 */
export async function verifyLoginContactUs(
  { loginTypeForGio } = {},
  targetUrl,
  params,
  router,
  lang,
  needAuth,
  userContactInfo,
  solutionId,
  description
) {
  let replaceUrl = ''
  let replaceUrl1 = ''
  let replaceUrl2 = ''
  for (const key in params) {
    if (key === 'pathName') {
      replaceUrl1 = replaceUrl1 + '/' + params[key]
    } else {
      replaceUrl2 = replaceUrl2 + key + '=' + params[key] + '&'
    }
  }
  replaceUrl = replaceUrl1 + '?' + replaceUrl2
  const lastCode = replaceUrl.slice(replaceUrl.length - 1)
  if (lastCode === '?' || lastCode === '&') {
    replaceUrl = replaceUrl.slice(0, replaceUrl.length - 1)
  }
  if (localStorage.getItem('contact')) {
    localStorage.removeItem('contact')
  }
  if (description && userContactInfo && solutionId) {
    const contactIds = {
      solutionId: solutionId || '',
      uuid: userContactInfo?.uuid || '',
      description: description || {},
    }
    localStorage.setItem('contact', JSON.stringify(contactIds))
  }
  if (needAuth) {
    const userInfoObj = JSON.parse(window.sessionStorage.getItem('userInfo'))
    if (userInfoObj) {
      userInfoObj.completeFlag = !!userContactInfo?.uuid
      window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoObj))
    }
    let authStatus = ''
    let reject = ''
    if (params.demoId) {
      await getUserContact({ product_id: solutionId, demo_id: params.demoId }).then((res) => {
        userContactInfo.uuid = res.data.uuid
        authStatus = res.data.authStatus
        reject = res.data.demoApplyRejectionReason
      })
    }
    // 未登陆，跳到登陆页
    if (!userInfoObj) {
      getLoginUrl(targetUrl, params, lang, loginTypeForGio)
    } else if (userContactInfo?.uuid === null || !userContactInfo?.uuid) {
      // 已登录未留资，跳到留资页面
      params.returnUrl = targetUrl
      router.push({ name: 'contact-us', query: params })
    } else if (userContactInfo?.uuid !== null) {
      // history.replaceState({ page: 111 }, 'lastPage', '/solution-details?id=' + params.id)
      // history.replaceState({ page: 111 }, 'lastPage', replaceUrl)
      if (encodeURI(targetUrl).includes('wjx.cn')) {
        getOnceCode({ token: localStorage.getItem('token') }).then((res) => {
          if (res.code === 200) {
            urlJumpCompatibleMobile(
              `${process.env.VUE_APP_WJX_BASE_URL}${process.env.VUE_APP_WJX_PATH}&code=${res.data.code}`,
              '_blank'
            )
            // window.location.href = `${process.env.VUE_APP_WJX_BASE_URL}${process.env.VUE_APP_WJX_PATH}&code=${res.data.code}`
          } else {
            alert(res.msg)
          }
        })
      } else {
        console.log(targetUrl, 'targetUrltargetUrltargetUrl')
        // 如无需登录留资或已登录留资，直接跳到目标页面
        if (encodeURI(targetUrl).includes('http')) {
          if (targetUrl.includes('activitycard/index')) {
            urlJumpCompatibleMobile(targetUrl, '_blank')
          } else {
            if (params?.demoId && params?.isEmpower === 'true') {
              // 需要授权的demo
              if (authStatus === '1') {
                console.log('solutionId-----111111111111111', solutionId)
                const res = await getShareAccessToken({ productId: solutionId || null })
                const attrVal = targetUrl.includes('?') ? '&' : '?'
                store.commit('setDemoStatus', {
                  show: true,
                  type: 1,
                  accountInfo: params?.showAccount
                    ? {
                        user: decodeURIComponent(decodeURIComponent(params.demoUser)),
                        pwd: decodeURIComponent(decodeURIComponent(params.demoPwd)),
                      }
                    : null,
                  fn: () => {
                    urlJumpCompatibleMobile(
                      encodeURI(
                        targetUrl + attrVal + 'accessToken=' + (res?.data?.accessToken || '')
                      ),
                      '_blank'
                    )
                  },
                })
                // setTimeout(() => {
                //   urlJumpCompatibleMobile(encodeURI(targetUrl + '?accessToken=' + (res?.data?.accessToken || '')), '_blank')
                //   store.commit('setDemoStatus', {show: false, type: ''})
                // }, 3000)
              } else if (authStatus === '0') {
                store.commit('setDemoStatus', {
                  show: true,
                  type: 0,
                  email: decodeURIComponent(decodeURIComponent(params.email)),
                })
              } else if (authStatus === '2') {
                store.commit('setDemoStatus', {
                  show: true,
                  type: 2,
                  email: decodeURIComponent(decodeURIComponent(params.email)),
                  reject,
                })
              }
            } else {
              if (params?.showAccount) {
                // 提供了demo账号密码
                store.commit('setDemoStatus', {
                  show: true,
                  type: 3,
                  accountInfo: {
                    user: decodeURIComponent(decodeURIComponent(params.demoUser)),
                    pwd: decodeURIComponent(decodeURIComponent(params.demoPwd)),
                  },
                  fn: () => {
                    urlJumpCompatibleMobile(encodeURI(targetUrl), '_blank')
                  },
                })
              } else {
                if (targetUrl.includes('smesip.com')) {
                  const res = await getAutoLoginUimAccessToken({
                    redirectPath: 'https://www.smesip.com/user/loginsso',
                  })
                  const attrVal = targetUrl.includes('?') ? '&' : '?'
                  urlJumpCompatibleMobile(
                    encodeURI(
                      targetUrl + attrVal + 'accessToken=' + (res?.data?.accessToken || '')
                    ),
                    '_blank'
                  )
                } else {
                  urlJumpCompatibleMobile(
                    encodeURI(
                      targetUrl +
                        (isWeChatBrowser() ? `&wechatToken=${localStorage.getItem('token')}` : '')
                    ),
                    '_blank'
                  )
                }
                // urlJumpCompatibleMobile(encodeURI(targetUrl), '_blank')
              }
            }
          }
        } else {
          if (targetUrl === 'default') {
            router.push({ name: 'solution-details', query: { id: params.id } })
          } else {
            window.location.href = encodeURI(targetUrl)
          }
        }
        // urlJumpCompatibleMobile(encodeURI(targetUrl), '_blank')
      }
    }
  } else {
    // history.replaceState({ page: 111 }, 'lastPage', '/solution-details?id=' + params.id)
    history.replaceState({ page: 111 }, 'lastPage', replaceUrl)
    // 如无需登录留资或已登录留资，直接跳到目标页面
    // urlJumpCompatibleMobile(encodeURI(targetUrl), '_blank')
    if (encodeURI(targetUrl).includes('http')) {
      urlJumpCompatibleMobile(encodeURI(targetUrl), '_blank')
    } else {
      window.location.href = encodeURI(targetUrl)
    }
  }
}

export function getLoginUrl(targetUrl, params, lang, loginTypeForGio) {
  let strUrl = 'contact-us'
  let strParams = '?returnUrl=' + encodeURIComponent(encodeURIComponent(targetUrl))
  let queryParams = ''
  if (params) {
    for (const param in params) {
      queryParams += '&' + param + '=' + params[param]
    }
  }
  strParams = strParams + encodeURIComponent(queryParams)
  strUrl += encodeURIComponent(strParams)
  gotoUimLogin(strUrl, lang, { loginTypeForGio })
}

export async function SetJumpURL(strParams, router, demoStatus, reject) {
  const u = navigator.userAgent
  // 判断是Android和IOS时解码URL
  if (
    u.indexOf('Android') > -1 ||
    u.indexOf('Adr') > -1 ||
    u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  ) {
    strParams = decodeURIComponent(strParams)
  }
  // 是否查看资料
  let isDetailLink = false
  try {
    isDetailLink = /resourceId=document_/g.test(strParams)
  } catch (e) {
    console.error(e)
  }
  const params = new URLSearchParams(strParams)
  const isWhitePaper = localStorage.getItem('contact')
    ? JSON.parse(localStorage.getItem('contact')).description.type === '白皮书'
    : false
  if (sessionStorage.getItem('isContactSubmit') === 'Y') {
    sessionStorage.removeItem('isContactSubmit')
    router.push(
      '/solution-details?id=' +
        params.get('id') +
        `${isWhitePaper ? '&whitePaper=true' : ''}` +
        `${params.get('source') ? '&source=' + params.get('source') : ''}`
    )
  }
  if (params.get('pathName') === 'solution-details') {
    const id = params.get('id')
    const name = params.get('pathName')
    // history.replaceState({ page: 111 }, 'lastPage', '/solution-details?id=' + params.get('id') + `${isWhitePaper ? '&whitePaper=true' : ''}`)
    if (params.get('returnUrl')) {
      const url = params.get('returnUrl')
      if (url.includes('wjx.cn')) {
        getOnceCode({ token: localStorage.getItem('token') }).then((res) => {
          if (res.code === 200) {
            // window.location.href = `${process.env.VUE_APP_WJX_BASE_URL}${process.env.VUE_APP_WJX_PATH}&code=${res.data.code}`
            urlJumpCompatibleMobile(
              `${process.env.VUE_APP_WJX_BASE_URL}${process.env.VUE_APP_WJX_PATH}&code=${res.data.code}`
            )
            router.push(
              '/solution-details?id=' +
                params.get('id') +
                `${isWhitePaper ? '&whitePaper=true' : ''}` +
                `${params.get('source') ? '&source=' + params.get('source') : ''}`
            )
          } else {
            alert(res.msg)
          }
        })
      } else {
        if (params.get('returnUrl')) {
          // window.location.href = params.get('returnUrl')
          if (params.get('returnUrl') !== 'default') {
            if (demoStatus === '0') {
              store.commit('setDemoStatus', {
                show: true,
                type: 0,
                email: decodeURIComponent(params.get('email')),
              })
            } else if (demoStatus === '1') {
              const res = await getShareAccessToken()
              const attrVal = params.get('returnUrl').includes('?') ? '&' : '?'
              store.commit('setDemoStatus', {
                show: true,
                type: 1,
                accountInfo: params.get('showAccount')
                  ? {
                      user: decodeURIComponent(params.get('demoUser')),
                      pwd: decodeURIComponent(params.get('demoPwd')),
                    }
                  : null,
                fn: () => {
                  urlJumpCompatibleMobile(
                    params.get('returnUrl') +
                      attrVal +
                      'accessToken=' +
                      (res?.data?.accessToken || ''),
                    '_blank'
                  )
                },
              })
              // setTimeout(() => {
              //   urlJumpCompatibleMobile(params.get('returnUrl') + '?accessToken=' + ((res?.data?.accessToken || '')), '_blank')
              //   store.commit('setDemoStatus', {show: false, type: ''})
              // }, 3000)
            } else if (demoStatus === '2') {
              store.commit('setDemoStatus', {
                show: true,
                type: 2,
                email: decodeURIComponent(params.get('email')),
                reject,
              })
            } else {
              if (params.get('showAccount')) {
                // 提供了demo账号密码
                if (isDetailLink) {
                  urlJumpCompatibleMobile(
                    params.get('returnUrl') +
                      (isWeChatBrowser() ? `&wechatToken=${localStorage.getItem('token')}` : ''),
                    '_blank'
                  )
                } else {
                  store.commit('setDemoStatus', {
                    show: true,
                    type: 3,
                    accountInfo: {
                      user: decodeURIComponent(params.get('demoUser')),
                      pwd: decodeURIComponent(params.get('demoPwd')),
                    },
                    fn: () => {
                      urlJumpCompatibleMobile(
                        params.get('returnUrl') +
                          (isWeChatBrowser()
                            ? `&wechatToken=${localStorage.getItem('token')}`
                            : ''),
                        '_blank'
                      )
                    },
                  })
                }
              } else {
                if (params.get('returnUrl').includes('smesip.com')) {
                  const res = await getAutoLoginUimAccessToken({
                    redirectPath: 'https://www.smesip.com/user/loginsso',
                  })
                  const attrVal = params.get('returnUrl').includes('?') ? '&' : '?'
                  window.location.href =
                    params.get('returnUrl') +
                    attrVal +
                    'accessToken=' +
                    (res?.data?.accessToken || '') // + '&rurl='
                } else {
                  urlJumpCompatibleMobile(
                    params.get('returnUrl') +
                      (isWeChatBrowser() ? `&wechatToken=${localStorage.getItem('token')}` : ''),
                    '_blank'
                  )
                }
              }
            }
          }
          router.push(
            '/solution-details?id=' +
              params.get('id') +
              `${isWhitePaper ? '&whitePaper=true' : ''}` +
              `${params.get('source') ? '&source=' + params.get('source') : ''}`
          )
        } else {
          setTimeout(() => {
            router.push({ name, query: { id } })
          }, 1000)
        }
      }
    }
    // else {
    //   // 增加没有returnUrl时的处理：跳转回solution-details页面，避免url变化但并未跳转问题出现
    //   router.push('/solution-details?id=' + params.get('id') + `${isWhitePaper ? '&whitePaper=true' : ''}` + `${params.get('source') ? '&source=' + params.get('source') : ''}`)
    // }
  } else if (params.get('pathName') === 'study-center-details') {
    // 跳到study-center-details
    router.push({
      name: params.get('pathName'),
      query: { id: params.get('id'), courseId: params.get('courseId') },
    })
  } else if (params.get('pathName') === '') {
    history.replaceState({ page: 111 }, 'lastPage', '/')
    if (params.get('returnUrl')) {
      if (sessionStorage.getItem('isOutside')) {
        urlJumpCompatibleMobile(params.get('returnUrl'), '_blank')
        router.push('/')
      } else {
        if (params.get('checkSigreen')) {
          const res = await getAutoLoginUimAccessToken({
            redirectPath: 'https://www.smesip.com/user/loginsso',
          })
          const attrVal = params.get('returnUrl').includes('?') ? '&' : '?'
          window.location.href =
            params.get('returnUrl') + attrVal + 'accessToken=' + (res?.data?.accessToken || '') // + '&rurl='
        } else {
          window.location.href = params.get('returnUrl')
        }
      }
    }
  }
}

export function articleJump(article, router) {
  if (article.articleType === 'successstory') {
    // article.articleType === 'Success Story' || article.articleType === '成功案例' ||
    if (article.showType === 'article') {
      router.push({
        name: 'articles-details',
        query: { id: article.id },
      })
      const targetUrl = `${window.location.origin}/articles-details?id=${article.id}`
      return targetUrl
    }
  } else if (article.articleType === 'faq') {
    router.push({
      name: 'articles-details',
      query: { faqID: article.id },
    })
    const targetUrl = `${window.location.origin}/articles-details?id=${article.id}`
    return targetUrl
  }
  if (article.showType === 'doc') {
    if (article.documentUrl) {
      const targetUrl = article.documentUrl
      urlJumpCompatibleMobile(encodeURI(new FileInfo(article.documentUrl).url), '_blank')
      return targetUrl
    }
  } else if (article.showType === 'url') {
    if (article.externalLink) {
      const targetUrl = article.externalLink
      urlJumpCompatibleMobile(encodeURI(complementaryURL(article.externalLink)), '_blank')
      return targetUrl
    }
  } else if (article.showType === '3') {
    router.push({
      name: 'articles-details',
      query: { id: article.id },
    })
    const targetUrl = `${window.location.origin}/articles-details?id=${article.id}`
    return targetUrl
  }
}
/**
 * 判断URL是否含有https://或http://，不完整补上OSS路径
 *
 * @export
 * @param {string | undefined} url
 * @param {'private' | 'public' | undefined} needPrivate
 * @return {string}
 */
export function complementaryURL(url, needPrivate = 'public') {
  if (url) {
    if (url.indexOf('https://') === -1 && url.indexOf('http://') === -1) {
      if (needPrivate === 'private') {
        // dev模拟siemens-x ********* 登录  siemens-x
        var isSiemensx = /^(www.)?siemens-x/g.test(window.location.hostname.toLowerCase())
        const envUrl = isSiemensx ? 'https://www.siemens-x.com.cn/' : process.env.VUE_APP_SERVER_URL
        const ossGetUrl = `${envUrl}marketplace-user/oss/private/get?ossKey=`
        url =
          ossGetUrl +
          checkSlashSubstr(url) +
          (isWeChatBrowser() ? `&wechatToken=${localStorage.getItem('token')}` : '')
      } else {
        url = process.env.VUE_APP_OSS_BASE_URL + (url.startsWith('/') ? url : '/' + url)
      }
    }
  } else {
    url = ''
  }

  return url
}

export function getCookies() {
  let strCookies = ''
  if (localStorage.getItem('ActPersonalInfo')) {
    const arr = localStorage.getItem('ActPersonalInfo').split(';')
    if (arr[0] === 'true') {
      // true:接受保存Cookie
      const arrCookie = document.cookie.split('; ')
      let isExpires = false
      let token = ''
      for (let i = 0; i < arrCookie.length; i++) {
        const val = arrCookie[i].split('=')
        if (val[0] === 'marketplace_00091800_u_id') {
          isExpires = true
          token = val[1]
          break
        }
      }
      if (isExpires) {
        strCookies = token
      } else {
        const d = new Date()
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000)
        const expires = 'expires=' + d.toUTCString()
        document.cookie =
          'marketplace_00091800_u_id' + '=' + localStorage.getItem('token') + '; ' + expires
        strCookies = localStorage.getItem('token')
      }
    }
  }
  return strCookies
}

// 设置视频第一帧为默认图片
export function setVideoFirstDefault(id) {
  const canvas = document.createElement('canvas')
  const video = document.getElementById(id)
  if (video) {
    video.setAttribute('crossOrigin', 'anonymous')
    video.currentTime = 1
    canvas.width = video.clientWidth
    canvas.height = video.clientHeight
    video.onloadeddata = () => {
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
      const dataURL = canvas.toDataURL('image/png')
      video.setAttribute('poster', dataURL)
    }
  }
}

export function isPC() {
  return document.body.clientWidth > 500
}
// 兼容移动端的url跳转
export function urlJumpCompatibleMobile(url) {
  const system = navigator.userAgent
  const isAndroid = system.indexOf('Android') > -1 || system.indexOf('Adr') > -1 // android终端
  const isiOS = !!system.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  if (isAndroid) {
    window.open(url)
  } else if (isiOS) {
    window.location.href = url
  } else {
    window.open(url)
  }
}

/**
 * 获取URL内参数
 * @param {*} url url link
 * @param {*} name 参数名
 * @returns 参数值
 */
export function getUrlQueryString(url, name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = url(1).match(reg) // 获取url中"?"符后的字符串并正则匹配
  var context = ''
  if (r != null) context = decodeURIComponent(r[2])
  reg = null
  r = null
  return context == null || context == '' || context == 'undefined' ? '' : context
}

function sortASCII(jsonObj) {
  const arr = []
  let num = 0
  for (const i in jsonObj) {
    arr[num] = i
    num++
  }
  const sortArr = arr.sort()
  const sortObj = {}
  for (const i in sortArr) {
    sortObj[sortArr[i]] = jsonObj[sortArr[i]]
  }

  return sortObj
}

function refrishTimeFn(num = 0) {
  if (num < 3) {
    // 密钥签名
    const obj = { appId: '221027', checkStr: '852d297b0eefbfa8ce05b9befbb71b2d' }
    // const strASCII = sortASCII(localStorage.getItem('rToken'))
    // const signParam = sha1(JSON.stringify(strASCII)).toLocaleUpperCase()
    refreshToken(localStorage.getItem('rToken') || '', {
      params: obj,
      headers: {
        sign: sha1(
          JSON.stringify(sortASCII({ ...obj, refreshToken: localStorage.getItem('rToken') }))
        ).toLocaleUpperCase(),
      },
    }).then((res) => {
      console.log('尝试获取次数' + (num + 1))
      if (res?.data?.refreshToken && res?.data?.token) {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('rToken', res.data.refreshToken)
        updateTokenRefrishTime()
        setTimeout(() => {
          window.location.reload()
        }, 50)
      } else {
        const reTry = num + 1
        refrishTimeFn(reTry)
      }
    })
  }
}

/**
 * 更新token定时器
 */
let tokenTimer = null
export function createUpdateTokenTimer() {
  const currentTime = new Date().getTime() // 当前时间
  const targetTime = localStorage.getItem('refrishTime') || new Date().getTime() // 目标时间
  const timeToWait = parseInt(targetTime) - currentTime // 等待时间
  if (timeToWait > 0) {
    tokenTimer && clearTimeout(tokenTimer)
    tokenTimer = setTimeout(() => {
      refrishTimeFn()
    }, timeToWait)
  }
}

/**
 * 设置最新token后，再次刷新的时间
 */
export function updateTokenRefrishTime() {
  const refrishTime = new Date().getTime() + 55 * 60 * 1000 // 设定定时刷新时间，一小时减5分钟后
  localStorage.setItem('refrishTime', refrishTime)
}

export function sortBy(field) {
  return (x, y) => {
    return x[field] - y[field]
  }
}

export function setDefineFormPoint(point) {
  localStorage.setItem('point', JSON.stringify(point))
}

export function getDefineFormPoint() {
  return (localStorage.getItem('point') && JSON.parse(localStorage.getItem('point'))) || {}
}

export function setClueCampaignPoint(campaign) {
  localStorage.setItem('campaign', JSON.stringify(campaign))
}

export function getClueCampaignPoint() {
  return (localStorage.getItem('campaign') && JSON.parse(localStorage.getItem('campaign'))) || {}
}

/**
 * 增加微信浏览器校验
 */
export function isWeChatBrowser() {
  var userAgent = navigator.userAgent.toLowerCase()
  return /micromessenger/.test(userAgent)
}
/**
 * 增加数字千分位分隔符
 */
export function getToLocaleString(number) {
  if (number || number === 0) {
    // const supplement = number.toString().includes('.') ? '' : '.00'
    // return number.toLocaleString() + supplement
    return number.toFixed(2)
  }
}
/**
 * 计算保留2位小数
 */
export function getCalcPrice(number) {
  if (number && number.toString().includes('.')) {
    return number.toFixed(2)
  } else {
    return number
  }
}
/**
 * 精确计算加法
 */
export function getCalcPlus(num1 = 0, num2 = 0) {
  let c = 0;
	const a = BigNumber(num1);
	const b = BigNumber(num2);
	c = a.plus(b).toNumber();
	return c;
}
/**
 * 精确计算乘法
 */
export function getCalcMultiply(num1 = 0, num2 = 0) {
  let c = 0;
	const a = BigNumber(num1);
	const b = BigNumber(num2);
	c = a.multipliedBy(b).toNumber();
	return c;
}

/**
 * 增加复制到剪切板方法
 */
export function copyClipboard(textToCopy) {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      alert('文本已复制到剪贴板！')
    })
    .catch((error) => {
      console.error('复制失败:', error)
    })
}

/**
 * 增加复制到剪切板方法
 */
export function copyClipboard2(textToCopy) {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      message.open({
        content: h(ErrorMessage, {
          msg: '文本已复制到剪贴板！',
          icon: 'icon-Success',
          showClose: false,
        }),
        duration: 3,
        zIndex: 1040,
        class: 'custom-success-message-wrap',
        // style: {
        //   marginTop: '80px',
        // },
      })
    })
    .catch((error) => {
      console.error('复制失败:', error)
    })
}

/**
 * 深复制递归(支持正则对象)
 */
export function deepClone(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  const clone = Array.isArray(obj) ? [] : {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      // 处理正则表达式对象
      if (value instanceof RegExp) {
        clone[key] = new RegExp(value)
      }
      // 递归处理其他类型的属性
      else {
        clone[key] = deepClone(value)
      }
    }
  }
  return clone
}

/**
 * 根据对象名称去重
 */
export function unique(arr, itemName) {
  const res = new Map()
  return arr.filter(
    (item) =>
      !res.has(item?.detail?.[itemName] || item?.[itemName]) &&
      res.set(item?.detail?.[itemName] || item?.[itemName], 1)
  )
}

/**
 * 正则过滤emoji
 */
export function filterEmoji(val) {
  const u = navigator.userAgent
  if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return val.replace(
      /(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g,
      ''
    )
  } else {
    const ranges = [
      '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
    ].join('|')
    return val.replace(new RegExp(ranges, 'g'), '')
  }
}

/**
 * 链接首位斜杠判断
 */
export function checkSlashSubstr(url) {
  return url.startsWith('/') ? url.substr(1) : url
}

export function urlQueryToObject(url) {
  const search = new URL(url).search // 提取查询字符串部分
  const searchParams = new URLSearchParams(search)

  // 将URLSearchParams对象转换为普通的JavaScript对象
  const queryParams = {}
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value
  }

  return queryParams
}

/**
 * 防抖函数
 * @param {*} func
 * @param {*} wait
 * @returns
 */
export function debounce(func, wait) {
  let timeout // 创建一个定时器变量
  return function () {
    const context = this // 保存当前上下文
    const args = arguments // 保存传入的参数
    // 如果定时器已经存在，则清除定时器
    if (timeout !== undefined) {
      clearTimeout(timeout)
    }
    // 设置新的定时器
    timeout = setTimeout(() => {
      func.apply(context, args) // 使用apply调用原函数，保证this的上下文和参数都正确
    }, wait)
  }
}
